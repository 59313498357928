import { FormattedMessage } from 'react-intl'
import { useAnalytics } from 'hooks'
import {
  and,
  assoc,
  equals,
  gt,
  isEmpty,
  isNil,
  length,
  not,
  prop,
  reject
} from 'ramda'
import { Button, CircularProgress, Stack } from '@mui/material'
import { useSignUpMutation } from 'api/authApi'
import { useSelector } from 'react-redux'
import { getBasicBodyCopy } from 'store/authenticationSlice'

import ActiveConsentForm from './ActiveConsentForm'
import UserTypeSelect from './UserTypeSelectConnector'
import EmailAndPassword from 'components/authentication/EmailAndPassword'
import LocationSelect from './LocationSelectConnector'
import Toast from 'components/toast/Toast'
import { useMemo } from 'react'

const BasicSignUpForm = () => {
  const { domain, has_secondary_user_type, locations } = useSelector(
    prop('school')
  )
  const { consent, email, location, password, revalidation, userType } =
    useSelector(prop('authentication'))
  const { sendEvent } = useAnalytics()

  const [signUp, { isLoading, data }] = useSignUpMutation()

  const body = useSelector(({ authentication }) =>
    getBasicBodyCopy(authentication)
  )

  const onSubmit = async () => {
    try {
      const data = await signUp(
        reject(isNil, assoc('org_domain', domain, body))
      )
      // Because the BE sends a true false success prop
      // instead of a proper error response
      if (prop('success', data))
        sendEvent('sign-up', {
          auth_type: 'traditional',
          // eslint-disable-next-line quotes
          validation_success: true
        })
      if (not(prop('success', data))) {
        sendEvent('sign-up', {
          auth_type: 'traditional',
          // eslint-disable-next-line quotes
          validation_success: false
        })
      }
    } catch (err) {
      console.error('error signing up', err)
    }
  }

  const isBtnDisabled = useMemo(() => {
    // Has consented
    if (not(consent)) return true
    // entered email
    if (isEmpty(email)) return true
    // entered password
    if (isEmpty(password)) return true
    // re-entered password
    if (isEmpty(revalidation)) return true
    // password and revalidation match
    if (not(equals(password, revalidation))) return true
    // If school has more than 1 location, location is selected
    if (and(gt(length(locations), 1), isNil(location))) return true
    // If school has staff/fac, user type is selected
    if (and(has_secondary_user_type, isNil(userType))) return true
    return false
  }, [
    consent,
    email,
    location,
    locations,
    password,
    revalidation,
    userType,
    has_secondary_user_type
  ])

  return (
    <Stack
      component="form"
      onSubmit={e => {
        e.preventDefault()
        onSubmit()
      }}
      spacing={3}
      sx={{
        maxWidth: 480,
        width: 1
      }}
    >
      <Toast
        isShown={prop('success', data)}
        message={
          <FormattedMessage
            defaultMessage="Success! Please check your email for next steps."
            id="valUz6"
          />
        }
      />
      <Toast
        isShown={and(not(isNil(data)), not(prop('success', data)))}
        message={prop('message', data)}
        type="error"
      />

      {gt(length(locations), 1) && <LocationSelect />}

      {has_secondary_user_type && <UserTypeSelect />}

      <EmailAndPassword revalidate />

      <ActiveConsentForm />

      <Button disabled={isBtnDisabled} fullWidth type="submit">
        {isLoading ? (
          <CircularProgress size={24} />
        ) : (
          <FormattedMessage defaultMessage="Create Account" id="5JcXdV" />
        )}
      </Button>
    </Stack>
  )
}

export default BasicSignUpForm
