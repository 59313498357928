import { all, and, equals, isNil, not, any, path } from 'ramda'
import { useEffect } from 'react'
import { useReactCookies } from 'hooks'
import { onAuthRoute } from 'utils/route-helpers'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

// This can be our new route and auth checker
// If logged in then direct where neede
// else move them out
const AuthorizationHandler = () => {
  const push = useNavigate()
  const { pathname } = useLocation()
  const { clear } = useReactCookies()
  const id = useSelector(path(['user', 'id']))

  const onRouteToClearStorage = any(equals(true))([
    equals(pathname, '/'),
    onAuthRoute(pathname)
  ])

  useEffect(() => {
    if (onRouteToClearStorage) {
      clear()
    }
  }, [clear, onRouteToClearStorage])

  // After logging in
  // once user data has been fetched and there is a redirect route
  // push the user to redirected route
  // reset redirect to null
  const isAuthRoute = onAuthRoute(pathname)
  useEffect(() => {
    const redirect = localStorage.getItem('redirect')
    if (
      all(equals(true))([
        not(isNil(redirect)),
        not(isNil(id)),
        not(isAuthRoute)
      ])
    ) {
      push(redirect)
      localStorage.removeItem('redirect')
    }
  }, [id, isAuthRoute, push])

  useEffect(() => {
    if (and(equals(pathname, '/'), not(isNil(id)))) {
      push('/home')
    }
  }, [id, pathname, push])
}

export default AuthorizationHandler
