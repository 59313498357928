import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle
} from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { useSession, useAnalytics, useReactCookies } from 'hooks'
import { useCallback, useEffect } from 'react'
import { useLogoutMutation } from 'api/authApi'
import { and, equals, prop } from 'ramda'
import { resetState } from 'store/userSlice'
import { useDispatch, useSelector } from 'react-redux'
import { setVisitTime } from 'store/historySlice'

const Logout = () => {
  const { sso_enabled, sso_logout_link } = useSelector(prop('school'))
  const { visitTime } = useSelector(prop('history'))
  const dispatch = useDispatch()
  const { clear } = useReactCookies()
  const { clearStorage } = useSession()
  const { sendEvent } = useAnalytics()

  const [logout] = useLogoutMutation()

  const handleAfter = useCallback(() => {
    dispatch(setVisitTime(null))
    clear()
    clearStorage()
    dispatch(resetState())
    window.location = and(equals('1', sso_enabled), sso_logout_link)
      ? sso_logout_link
      : '/'
  }, [clear, clearStorage, dispatch, sso_enabled, sso_logout_link])

  useEffect(() => {
    const handleLogout = async () => {
      try {
        await logout()
        sendEvent('time_on_site', {
          time_on_site: Math.floor(Date.now() / 1000) - visitTime
        })
        dispatch(setVisitTime(null))
        handleAfter()
      } catch (err) {
        console.error('error logging out', err)
      }
    }

    handleLogout()
    // Dependencies will make site rerender 20 times on page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Dialog
      aria-labelledby="dialog-title"
      open
      PaperProps={{
        sx: {
          height: 200,
          width: 375
        }
      }}
      role="alertdialog"
    >
      <DialogTitle component="h1" id="dialog-title" tabIndex={-1}>
        <FormattedMessage defaultMessage="Logging Out..." id="u6iQRM" />
      </DialogTitle>
      <DialogContent
        sx={{
          alignItems: 'center',
          color: 'primary.swatch',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <CircularProgress color="inherit" />
      </DialogContent>
    </Dialog>
  )
}

export default Logout
