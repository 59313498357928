import { useCallback } from 'react'
import { useCookies } from 'react-cookie'

const useReactCookies = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['sessionId'])
  const clear = useCallback(() => {
    removeCookie('sessionId', { path: '/' })
  }, [removeCookie])

  const get = useCallback(() => {
    const key = cookies.sessionId
    if (key) return key
    return 
  }, [cookies.sessionId])

  const save = useCallback(
    v =>  { 
      const date = new Date()
      date.setDate(date.getDate() + 1)
      setCookie('sessionId', v, {expires: date, path: '/' , sameSite: 'strict', secure: true }) },
    [setCookie]
  )

  return {
    clear,
    get,
    save
  }
}

export default useReactCookies
