import { default as guhBrowser } from 'ga-browser'
import { and, equals } from 'ramda'

class GuhService {
  constructor() {
    if (!GuhService.prototype.singleton) {
      this.guhBrowser = guhBrowser()
      GuhService.prototype.singleton = this
    }

    return GuhService.prototype.singleton
  }

  assembleProperties(props) {
    const properties = {
      dev_environment: process.env.REACT_APP_PUBLIC_KEENIO_ENV,
      keen_session_id: sessionStorage.getItem('@you:keenSessionId'),
      locale: 'en'
    }
    return Object.assign(properties, props)
  }

  initialize(trackingId, defaultCategory) {
    if (!this.isInitialized()) {
      this.isInit = true
      this.trackingId = trackingId
      this.defaultCategory = defaultCategory
      this.guhBrowser('create', trackingId, 'auto')
      this.pageview()
    }
  }

  isInitialized() {
    return !!this.isInit
  }

  event(...args) {
    if (process.env.BROWSER && this.isInitialized()) {
      this.guhBrowser.apply(this, args)
    }
  }

  pageload(args) {
    if (process.env.BROWSER) {
      args.page = args.page || window.location.pathname
    }
    if (!args.title) {
      delete args.title
    }
    this.event('send', {
      hitType: 'pageview',
      ...args
    })
  }

  pageview(path, title) {
    this.pageload({
      page: path,
      title
    })
  }

  getOrgDomain() {
    if (typeof window !== 'undefined') {
      // make sure we can access session storage
      return sessionStorage.getItem('org_domain')
    }
    return false
  }

  getSessionId() {
    if (typeof window !== 'undefined') {
      const nameEQ = 'sessionId='
      const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
          let cookie = cookies[i];
          while (equals(cookie.charAt(0), ' ')) {
            cookie = cookie.substring(1, cookie.length);
          }
          if (equals(cookie.indexOf(nameEQ), 0)) {
            return cookie.substring(nameEQ.length, cookie.length);
          }
        }
    }
    return null;
  }

  track(action, label) {
    //if not in the excluded ids, send event
    // const has_access = getCanViewStatsEndpoint()
    // if (!has_access) {
    //   this.event('send', 'event', this.defaultCategory, action, label)
    // }

    // prepare stream event for analytics event
    let properties = {
      ga_action: action,
      ga_category: this.defaultCategory,
      ga_label: label
    }
    const ORG_DOMAIN = this.getOrgDomain()
    const ANALYTICS_URL = '/v2/analytics?org_domain=' + ORG_DOMAIN

    if (typeof window !== 'undefined') {
      properties = this.assembleProperties(properties)

      const body = new FormData()
      body.append('event', 'ga_event')
      body.append('data', JSON.stringify(properties))

      fetch(`${process.env.REACT_APP_PUBLIC_YOU_API_HOST}${ANALYTICS_URL}`, {
        body,
        credentials: 'omit',
        headers: new Headers({
          'X-Session-Id': this.getSessionId(),
          accept: 'application/json',
          'keen-post-key': process.env.REACT_APP_PUBLIC_KEEN_POST_KEY
        }),
        method: 'POST',
        mode: 'cors'
      })
    }
  }

  click(action, label) {
    this.track(action || 'Click', label)
  }

  search(query) {
    this.track('Search', query)
  }
}

const GA = new GuhService()
export default GA
