import { useEffect, useState } from 'react'
import { add, equals, isNil, not } from 'ramda'
import { useAnalytics, useReactCookies, useSession } from 'hooks'
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress
} from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'

const AccountCreatedPage = () => {
  // example url: https://you.colostate.edu/sso/login/XPCnHpT3y6ZOIoMhl6ApHtOXrbflyunSssMgP3UI/no
  const navigate = useNavigate()
  const { accountCreated, token } = useParams()
  const { sendEvent } = useAnalytics()
  const { sessionEvent } = useSession()
  const { clear, get, save } = useReactCookies()
  const creatingAccount = equals('yes', accountCreated)

  const [progress, setProgress] = useState(25)

  useEffect(() => {
    if (not(isNil(token))) {
      setProgress(50)
      clear()
      // set the new x-session-id header
      save(token)
    }
  }, [clear, save, token])

  const sessionToken = get()

  useEffect(() => {
    const handleRouting = () => {
      setProgress(100)
      if (creatingAccount) {
        navigate('/onboarding/introduction')
      }
      if (not(creatingAccount)) {
        navigate('/home')
      }
    }

    if (not(isNil(sessionToken))) {
      // tracking
      sessionEvent('login', { session_id: token })
      sendEvent('login', {
        auth_type: 'sso',
        validation_errors: [],
        validation_success: true
      })
      sendEvent('sign-in', {
        auth_type: 'sso',
        validation_errors: [],
        validation_success: true
      })
      setProgress(75)
      handleRouting()
    }
  }, [creatingAccount, navigate, sendEvent, sessionEvent, sessionToken, token])

  return (
    <Dialog
      aria-labelledby="dialog-title"
      open
      PaperProps={{
        sx: {
          height: 200,
          width: 375
        }
      }}
      role="alertdialog"
    >
      <DialogTitle component="h1" id="dialog-title" tabIndex={-1}>
        {creatingAccount ? (
          <FormattedMessage
            defaultMessage="Finalizing your account..."
            id="YEBC3/"
          />
        ) : (
          <FormattedMessage defaultMessage="Logging You In..." id="S4ohHi" />
        )}
      </DialogTitle>
      <DialogContent
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Box width={1}>
          <LinearProgress
            color="inherit"
            value={progress}
            valueBuffer={add(progress, 10)}
            variant="buffer"
          />
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default AccountCreatedPage
