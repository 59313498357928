import { FormattedMessage } from 'react-intl'
import { Button, Container, Stack, Typography } from '@mui/material'
import { path } from 'ramda'
import { useVerifyUserMutation } from 'api/userApi'
import { useAnalytics, useReactCookies } from 'hooks'
import { setUserData } from 'store/userSlice'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import Revalidate from './Revalidate'
import Toast from 'components/toast/Toast'

const Verify = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { token } = useParams()
  const [search] = useSearchParams()
  const email = search.get('email')

  const { sendEvent } = useAnalytics()
  const { clear, save } = useReactCookies()

  const [submitVerify, { isSuccess, isError }] = useVerifyUserMutation()

  const handleSubmit = async () => {
    try {
      clear()
      const data = await submitVerify({ email, token }).unwrap()
      const sessionId = path(['headers', 'headers'], data).get('X-Session-Id')
      save(sessionId)
      // set the user in the context so that we get correct header behavior
      dispatch(setUserData(path(['res', 'user'], data)))
      sendEvent('pending_user_click', {})
      navigate('/onboarding/introduction')
    } catch (err) {
      console.warn('Error verifying:', err)
    }
  }

  const renderContent = () => {
    if (isError) {
      return <Revalidate />
    }

    return (
      <>
        <Typography textAlign="center" variant="h4">
          <FormattedMessage
            defaultMessage="Click the button below to verify your account and log in."
            id="LSuAiY"
          />
        </Typography>
        <Button disabled={isSuccess} onClick={handleSubmit}>
          <FormattedMessage defaultMessage="Verify" id="q5Xl0M" />
        </Button>
      </>
    )
  }

  return (
    <Container maxWidth="tablet" sx={{ minHeight: '100vh' }}>
      <Stack alignItems="center" spacing={3} sx={{ my: 'auto' }}>
        <Typography variant="h1">
          <FormattedMessage defaultMessage="Verify your email" id="lBs6BA" />
        </Typography>
        {renderContent()}
        <Toast
          isShown={isSuccess}
          message={
            <FormattedMessage
              defaultMessage="Success! Check your email to verify your new account."
              id="MGQUIN"
            />
          }
        />
      </Stack>
    </Container>
  )
}

export default Verify
